// Sidebar.jsx
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Drawer,
  List,
  ListItemText,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Badge,
  ListItemIcon,
  Avatar,
  ListItemButton,
} from "@mui/material";
import {
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  TableView as TableViewIcon,
  Cable as CableIcon,
  SchemaOutlined as SchemaOutlinedIcon,
  LandscapeOutlined as LandscapeOutlinedIcon,
  StorefrontOutlined as StorefrontOutlinedIcon,
  Calculate as CalculateIcon
} from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import { toTitleCase, formatPhoneNumber } from "../utils/utils";

const Sidebar = ({ tenant, user }) => {
  const { csm_user, tam_user, exec_sponsor_user, project_manager_user } = tenant;
  const theme = useTheme();
  const [selected, setSelected] = useState("launchpad");
  const [isExpanded, setIsExpanded] = useState(true);

  const { tenantId } = useParams();

  const unreadCounts = {
    users: 0,
    activity: 0,
    billing: 0,
    support: 3,
    meetings: 1,
    news: 4,
    ideas: 10,
  };

  const menuItems = () => {
    let items = [
      {
        name: "Marketplace",
        icon: <StorefrontOutlinedIcon />,
        component: "dashboard",
        path: `/tenants/${tenantId}/dashboard`,
      }
    ]

    // only Altvia users
    if (!user.tenant_id) {
      items.push({
        name: "Pricing",
        icon: <CalculateIcon />,
        component: "pricing",
        path: `/tenants/${tenantId}/pricing`,
      });
    }
    
    items = items.concat([
      {
        name: "Universal Data Model",
        icon: <SchemaOutlinedIcon />,
        component: "udm",
        path: `/tenants/${tenantId}/udm`,
      },
      {
        name: "Ascent",
        icon: <LandscapeOutlinedIcon />,
        component: "ascent",
        path: `/tenants/${tenantId}/ascent`,
      }
    ]);

    // only Altvia users
    if (!user.tenant_id) {
      items.push({
        name: "Connections",
        icon: <CableIcon />,
        component: "connections",
        path: `/tenants/${tenantId}/connections`,
      });
    }
    
    items = items.concat([
      {
        name: "Data Tables",
        icon: <TableViewIcon />,
        component: "data-tables",
        path: `/tenants/${tenantId}/data-tables`,
      },
      {
        name: "Users",
        icon: <PeopleAltOutlinedIcon />,
        component: "users",
        badge: unreadCounts.users,
        path: `/tenants/${tenantId}/users`,
      }
    ]);

    return items;
  };

  const handleListItemClick = (component, path) => {
    setSelected(component);
  };

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: 320,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 320,
          boxSizing: "border-box",
          backgroundColor: "#E4EBEF",
        },
      }}>
      <Box sx={{ overflow: "auto", paddingRight: 1, mb: "70px" }}>
        <Card sx={{ borderRadius: 0, paddingBottom: 0 }}>
          <Box sx={{ display: 'flex', pl: 2, pr: 2, pt: 2 }}>
            {tenant.logo_url ? (
              <img
                src={tenant.logo_url}
                alt="Customer Logo"
                style={{maxWidth: '100%', height: '100px', objectFit: 'contain'}}
              />
            ) : (
              <Typography variant='h5'>{tenant.name}</Typography>
            )}
          </Box>
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6'>Account Details</Typography>
              <IconButton onClick={toggleCollapse}>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
            </Box>
            <Collapse in={isExpanded}>
              <Typography variant='body1' color='textSecondary' gutterBottom>
                CAB Participant: {tenant.cab_participant ? "Yes" : "No"}
              </Typography>
              <Typography variant='body1' color='textSecondary' gutterBottom>
                Care Support: {toTitleCase(tenant.care_support)}
              </Typography>
              <Typography variant='body1' color='textSecondary' gutterBottom>
                Became Client On: {new Date(tenant.became_client_on).toLocaleDateString("en-US")}
              </Typography>
            </Collapse>
          </CardContent>
        </Card>

        <List>
          {menuItems().map((item) => (
            <ListItemButton
              key={item.component}
              selected={selected === item.component}
              component={Link}
              to={item.path}
              onClick={() => handleListItemClick(item.component, item.path)}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                  "& .MuiListItemIcon-root": {
                    color: theme.palette.primary.contrastText,
                  },
                  "& .MuiListItemText-primary": {
                    color: theme.palette.primary.contrastText,
                  },
                },
                "& .MuiListItemIcon-root": {
                  color: selected === item.component ? theme.palette.primary.contrastText : theme.palette.primary.main,
                },
              }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{
                  color: selected === item.component ? theme.palette.primary.contrastText : theme.palette.text.primary,
                }}
              />
              {item.badge > 0 && <Badge badgeContent={item.badge} color='error' />}
            </ListItemButton>
          ))}
        </List>
        {(csm_user || tam_user || exec_sponsor_user || project_manager_user) && (
          <>
            <Divider />
            <Box sx={{ p: 1 }}>
              {csm_user && (
                <Card variant='outlined' sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Avatar sx={{ width: 56, height: 56, ml: 1 }} src={csm_user.logo_url} />
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography variant='subtitle' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      {csm_user.first_name} {csm_user.last_name}
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      CSM
                    </Typography>
                    <Typography variant='body2'>
                      <a href={`mailto:${csm_user.email}`}>{csm_user.email}</a>
                    </Typography>
                    <Typography variant='body2'>{formatPhoneNumber(csm_user.phone)}</Typography>
                  </CardContent>
                </Card>
              )}
              {tam_user && (
                <Card variant='outlined' sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Avatar sx={{ width: 56, height: 56, ml: 1 }} src={tam_user.logo_url} />
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography variant='subtitle' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      {tam_user.first_name} {tam_user.last_name}
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      TAM
                    </Typography>
                    <Typography variant='body2'>
                      <a href={`mailto:${tam_user.email}`}>{tam_user.email}</a>
                    </Typography>
                    <Typography variant='body2'>{formatPhoneNumber(tam_user.phone)}</Typography>
                  </CardContent>
                </Card>
              )}
              {exec_sponsor_user && (
                <Card variant='outlined' sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Avatar sx={{ width: 56, height: 56, ml: 1 }} src={exec_sponsor_user.logo_url} />
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography variant='subtitle' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      {exec_sponsor_user.first_name} {exec_sponsor_user.last_name}
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      Executive Sponsor
                    </Typography>
                    <Typography variant='body2'>
                      <a href={`mailto:${exec_sponsor_user.email}`}>{exec_sponsor_user.email}</a>
                    </Typography>
                    <Typography variant='body2'>{formatPhoneNumber(exec_sponsor_user.phone)}</Typography>
                  </CardContent>
                </Card>
              )}
              {project_manager_user && (
                <Card variant='outlined' sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <Avatar sx={{ width: 56, height: 56, ml: 1 }} src={project_manager_user.logo_url} />
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography variant='subtitle' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      {project_manager_user.first_name} {project_manager_user.last_name}
                    </Typography>
                    <Typography variant='body2' sx={{ fontWeight: "bold", color: theme.palette.text.secondary }}>
                      Project Manager
                    </Typography>
                    <Typography variant='body2'>
                      <a href={`mailto:${project_manager_user.email}`}>{project_manager_user.email}</a>
                    </Typography>
                    <Typography variant='body2'>{formatPhoneNumber(project_manager_user.phone)}</Typography>
                  </CardContent>
                </Card>
              )}
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
