import { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton,
  Collapse,
  Alert
} from '@mui/material';
import { Check, Remove, ExpandMore, ExpandLess } from '@mui/icons-material';

const columnWidths = {
  features: '25%',
  tier: '20%',
  users: '15%'
};

const ModulesTable = ({ 
  modules, 
  selectedTier, 
  selectedModules, 
  userCounts,
  enterpriseSeats,
  onModuleSelect, 
  onUserCountChange,
  onEnterpriseSeatsChange,
  onTierChange 
}) => {
  const [expandedModules, setExpandedModules] = useState(new Set());

  const toggleModule = (moduleId) => {
    const newExpanded = new Set(expandedModules);
    if (newExpanded.has(moduleId)) {
      newExpanded.delete(moduleId);
    } else {
      newExpanded.add(moduleId);
    }
    setExpandedModules(newExpanded);
  };

  const renderFeatureValue = (value) => {
    if (typeof value === 'boolean') {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        {value ? <Check color="success" /> : <Remove color="disabled" />}
      </div>;
    }
    if (value === 'None') {
      return <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Remove color="disabled" />
      </div>;
    }
    return <div style={{ textAlign: 'center' }}>{value}</div>;
  };

  const commonCellStyles = {
    width: columnWidths.tier,
    padding: '8px 16px',
    borderRight: '1px solid rgba(224, 224, 224, 1)'
  };
  
  const tierCellStyles = (tier) => ({
    ...commonCellStyles,
    cursor: 'pointer',
    bgcolor: selectedTier === tier ? 'primary.light' : 'inherit',
    borderBottom: selectedTier === tier ? '2px solid' : 'inherit',
    borderBottomColor: 'primary.main',
    transition: 'all 0.2s',
    '&:hover': {
      bgcolor: selectedTier === tier ? 'primary.light' : 'grey.100'
    },
    '& .MuiTypography-root': {
      fontWeight: selectedTier === tier ? 'bold' : 'normal'
    }
  });
  
  return (
    <Paper sx={{ mb: 4, p: 3 }}>
      <Typography variant="h6" gutterBottom>Core Modules</Typography>
      <TableContainer>
        <Table size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell 
                sx={{ 
                  width: columnWidths.features,
                  borderRight: '1px solid rgba(224, 224, 224, 1)'
                }}
              >
                Features
              </TableCell>
              {['team', 'business', 'enterprise'].map((tier) => (
                <TableCell 
                  key={tier}
                  align="center"
                  onClick={() => onTierChange(tier)}
                  sx={tierCellStyles(tier)}
                >
                  <Typography sx={{ textTransform: 'capitalize' }}>
                    {tier}
                  </Typography>
                </TableCell>
              ))}
              <TableCell 
                align="center" 
                sx={{ 
                  width: columnWidths.users,
                  padding: '8px'
                }}
              >
                Users
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedTier === 'enterprise' && (
              <TableRow>
                <TableCell colSpan={4} sx={{ borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                  <Alert severity="info" sx={{ mb: 1 }}>
                    Enterprise tier includes all modules with unified user licensing
                  </Alert>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    size="small"
                    value={enterpriseSeats}
                    onChange={(e) => {
                      const value = parseInt(e.target.value) || 0;
                      onEnterpriseSeatsChange(value);
                    }}
                    InputProps={{ 
                      inputProps: { min: 25 },
                      sx: { textAlign: 'center' }
                    }}
                    sx={{ width: '80px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {Object.entries(modules).map(([moduleId, module]) => [
              <TableRow key={moduleId} sx={{ bgcolor: 'grey.50' }}>
                <TableCell 
                  colSpan={4} 
                  sx={{ 
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    padding: '8px 16px'
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                      size="small"
                      onClick={() => toggleModule(moduleId)}
                    >
                      {expandedModules.has(moduleId) ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedModules.includes(moduleId)}
                          onChange={(e) => onModuleSelect(moduleId, e.target.checked)}
                          disabled={selectedTier === 'enterprise'}
                        />
                      }
                      label={module.name}
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  {selectedModules.includes(moduleId) && selectedTier !== 'enterprise' && (
                    <TextField
                      type="number"
                      size="small"
                      value={userCounts[moduleId] || ''}
                      onChange={(e) => onUserCountChange(moduleId, parseInt(e.target.value) || 0)}
                      InputProps={{ 
                        inputProps: { min: 0 },
                        sx: { textAlign: 'center' }
                      }}
                      sx={{ width: '80px' }}
                    />
                  )}
                </TableCell>
              </TableRow>,
              <TableRow key={`${moduleId}-features`}>
                <TableCell colSpan={5} sx={{ padding: 0, border: 0 }}>
                  <Collapse in={expandedModules.has(moduleId)}>
                    <Table size="small" sx={{ tableLayout: 'fixed' }}>
                      <TableBody>
                        {module.features.map((feature, idx) => (
                          <TableRow key={`${moduleId}-${idx}`}>
                            <TableCell 
                              sx={{ 
                                width: columnWidths.features,
                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                padding: '8px 16px',
                                paddingLeft: '48px'
                              }}
                            >
                              {feature.name}
                            </TableCell>
                            <TableCell 
                              align="center" 
                              sx={commonCellStyles}
                            >
                              {renderFeatureValue(feature.team)}
                            </TableCell>
                            <TableCell 
                              align="center" 
                              sx={commonCellStyles}
                            >
                              {renderFeatureValue(feature.business)}
                            </TableCell>
                            <TableCell 
                              align="center" 
                              sx={commonCellStyles}
                            >
                              {renderFeatureValue(feature.enterprise)}
                            </TableCell>
                            <TableCell sx={{ width: columnWidths.users }} />
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ModulesTable;