import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Autocomplete, Chip, Stack } from '@mui/material';
import { Form, useFetcher } from 'react-router-dom';

export const settingsFields = ({readOnly, settings, onChange, formData}) => {
  const handleArrayChange = ({value, name}) => {
    // mimic TextField event
    onChange({ target: { name: name, value: value} })
  }
  return settings.map((field, index) => {
    if (field.type == 'string[]') {
      const values = formData[field.name] || [];
      return (
        <div key={index}>
          <Autocomplete
            disabled={readOnly}
            multiple
            options={[]}
            freeSolo
            value={values}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            onChange={(_event, value, _reason) => handleArrayChange({value, name: field.name})}
            renderInput={(params) => (
              <TextField
              {...params}
              variant="outlined"
              margin="normal"
              label="Custom Objects"
              placeholder="Type values and press Enter to add to list"
              />
            )}
          />
          {values.map((value) => {
            return <input type="hidden" name={`${field.name}[]`} value={value}/>
          })}
        </div>
      )
    } else {
      return (
        <TextField
          disabled={readOnly}
          key={index}
          label={field.label}
          type={field.type}
          name={field.name}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onChange}
          value={formData[field.name]}
        />
      )
    }
  })
}

const Settings = ({ service, connection, user }) => {
  const [formChanged, setFormChanged] = useState(false);
  const [formData, setFormData] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const fetcher = useFetcher();

  useEffect(() => {
    const fields = service.settings_fields || [];
    const initialFormData = {};

    fields.forEach(field => {
      const existingValue = connection.settings && connection.settings[field.name]
      const defaultValue = field.type == 'string[]' ? [] : ''
      initialFormData[field.name] = existingValue || defaultValue;
    });

    setFormData(initialFormData);
    setFormChanged(false);
  }, [service, connection]);

  useEffect(() => {
    const connectionCreating = connection.status == 'creating';
    setReadOnly(connectionCreating);
  }, [connection])

  return (
    <Box>
      <Form id="settings-form" method="post" action="update">
        {settingsFields({
          readOnly: readOnly || user.role == 'altvia_user',
          settings: [...service.settings_fields], // spreading makes reactive
          formData: {...formData}, // spreading makes function call reactive
          onChange: ((e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setFormChanged(true);
          })
        })}
      </Form>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <fetcher.Form method="post" action="test">
          <Button
            disabled={readOnly}
            variant="contained"
            type="submit"
            color="primary"
            >
            Test Connection
          </Button>
        </fetcher.Form>
        <fetcher.Form method="post" action="sync">
          <Button
            disabled={readOnly}
            variant="contained"
            type="submit"
            color="secondary"
            >
            Sync Data
          </Button>
        </fetcher.Form>
        <Button
          form="settings-form"
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={user.role == 'altvia_user' || readOnly || !formChanged}
        >
          Save Settings
        </Button>
      </Stack>
    </Box>
  );
};

export default Settings;
