import React, { useState, useMemo } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Button,
  InputBase,
  IconButton,
  Box} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import NewConnectionModal from './NewConnectionModal';
import logoMapping from '../../assets/images/services-logos';
import { Link, useParams } from 'react-router-dom';
import SubSidebar from '../../components/SubSidebar';

const ConnectionsSidebar = ({ connections, services, tenant, user }) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { connectionId } = useParams(); // get connection ID if in route

  const groupedConnections = connections.reduce((acc, connection) => {
    const serviceInfo = services.find((service) => 
      service.id == connection.service_id
    )
    const serviceName = serviceInfo ? serviceInfo.name : 'Unknown Service';
    if (!acc[serviceName]) {
      acc[serviceName] = {
        connections: [],
        logo: serviceInfo ? logoMapping[serviceInfo.logo] : undefined,
        defaultExpanded: false
      };
    }
    acc[serviceName].connections.push(connection);
    return acc;
  }, {});

  const servicesAndConnections = useMemo(() => {
    return Object.entries(groupedConnections).map(([serviceName, { connections, logo }], index) => (
      <Accordion
        key={serviceName}
        defaultExpanded={connections.some((c)=>c.id == connectionId)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {logo && <img src={logo} alt={`${serviceName} logo`} style={{ width: 24, height: 24 }} />}
            <Typography>{serviceName}</Typography>
          </Box>
        </AccordionSummary>
          <AccordionDetails>
          <List component="div" disablePadding>
            {connections.map((connection) => (
              <ListItemButton
                selected={connection.id == connectionId}
                key={connection.id}
                component={Link}
                to={`/tenants/${tenant.id}/connections/${connection.id}`}
              >
                <ListItemText
                  primary={connection.name}
                />
              </ListItemButton>
              )
            )}
          </List>
          </AccordionDetails>
      </Accordion>
    )
  )}, [connectionId]);

  // Handle modal open/close
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  return (
    <>
      <SubSidebar
        tenant={tenant}
        activeTab={'Connections'}
        user={user}
      >
        <Typography variant="h4" sx={{ pb: 2 }} >Connections</Typography>
        <InputBase
          sx={{ flex: 1 }}
          placeholder="Search connections"
          inputProps={{ 'aria-label': 'search connections' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        {(user.role == 'altvia_admin' || user.role == 'altvia_power_user') &&
          <Button
          sx={{ mt: 2 }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleModalOpen}
          variant="outlined"
          fullWidth
          >
            Create New Connection
          </Button>
        }
        {servicesAndConnections}
      </SubSidebar>
      <NewConnectionModal
        open={open}
        handleClose={handleModalClose}
        services={services}
        connections={connections}
      />
    </>
  );
};

export default ConnectionsSidebar;
