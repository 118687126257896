import { useState } from 'react';
import { 
  Container, 
  Tabs, 
  Tab, 
  Box, 
  Typography, 
  ThemeProvider,
  createTheme
} from '@mui/material';
import PricingCalculator from './PricingCalculator';
import ROICalculator from './ROICalculator';

// Nested theme applied on top of global MUI theme; see ThemeProvider below.
const pricingTheme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      light: '#E4EBEF',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function Pricing() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    // Providing a function allows extending the global theme. See:
    // https://mui.com/material-ui/customization/theming/#nesting-the-theme
    <ThemeProvider theme={(theme) => createTheme({...theme, ...pricingTheme})}>
      <Container maxWidth="lg" sx={{ py: 2 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          AltviaOne Platform Calculator
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs 
            value={currentTab} 
            onChange={handleTabChange}
            centered
          >
            <Tab label="Pricing Calculator" />
            <Tab label="ROI Calculator" />
          </Tabs>
        </Box>

        <Box role="tabpanel" hidden={currentTab !== 0}>
          {currentTab === 0 && <PricingCalculator />}
        </Box>
        <Box role="tabpanel" hidden={currentTab !== 1}>
          {currentTab === 1 && <ROICalculator />}
      </Box>
    </Container>
    </ThemeProvider>
  );
}

export default Pricing;