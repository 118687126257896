import {
  Paper,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider
} from '@mui/material';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const AddOns = ({
  addOns,
  selectedTier,
  selectedAddOns,
  userCounts,
  dataSources,
  onAddOnSelect,
  onUserCountChange,
  onDataSourcesChange
}) => (
  <Paper sx={{ p: 3 }}>
    <Typography variant="h6" gutterBottom>Add-ons</Typography>
    <Stack spacing={2}>
      {Object.entries(addOns).map(([addonId, addon]) => (
        <Box key={addonId}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedAddOns.includes(addonId)}
                disabled={!addon.availability[selectedTier]}
                onChange={(e) => onAddOnSelect(addonId, e.target.checked)}
              />
            }
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{addon.name}</Typography>
                <Typography variant="caption" color="text.secondary">
                  {addon.pricePerUser ? (
                    addon.availability[selectedTier] ? 
                      formatCurrency(addon.pricePerUser[selectedTier]) + '/user/year' :
                      'Not available'
                  ) : addonId === 'data' ? (
                    formatCurrency(5000) + '/source/year'
                  ) : (
                    `${addon.price[selectedTier].tokens} tokens - ${formatCurrency(addon.price[selectedTier].price)}/year`
                  )}
                </Typography>
              </Stack>
            }
          />
          {selectedAddOns.includes(addonId) && addon.availability[selectedTier] && (
            <Box sx={{ ml: 4, mt: 1 }}>
              {addon.pricePerUser && (
                <TextField
                  type="number"
                  size="small"
                  label="Users"
                  value={userCounts[addonId] || ''}
                  onChange={(e) => onUserCountChange(addonId, parseInt(e.target.value) || 0)}
                  InputProps={{ inputProps: { min: 0 } }}
                  sx={{ width: '120px' }}
                />
              )}
              {addonId === 'data' && (
                <TextField
                  type="number"
                  size="small"
                  label="Data Sources"
                  value={dataSources || ''}
                  onChange={(e) => onDataSourcesChange(parseInt(e.target.value) || 0)}
                  InputProps={{ inputProps: { min: 0 } }}
                  sx={{ width: '120px' }}
                />
              )}
            </Box>
          )}
          <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
            {addon.description}
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
      ))}
    </Stack>
  </Paper>
);

export default AddOns;