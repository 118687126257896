import React from "react";
import { Card, CardContent, Typography, Button, Box, IconButton, CardActions } from "@mui/material";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import { format } from "date-fns";

const ActiveProductCard = ({ product, handleOpenDrawer }) => (
  <Card
    sx={{
      height: "100%",
      cursor: "pointer",
      backgroundColor: "#f7f7f7",
      border: "1px solid #2E86AB",
      "&:hover": { backgroundColor: "#E6F7EC" },
    }}
    onClick={() => handleOpenDrawer(product)}>
    <CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant='h5'>{product.name}</Typography>
        {product.logo && (
          <img
            src={product.logo}
            alt={`${product.name} logo`}
            style={{ width: "40px", height: "40px" }}
          />
        )}

        {(product.subscription_details?.login_url || product.login_url) && (
          <Button
            variant='contained'
            color="primary"
            href={product.subscription_details.login_url || product.login_url}
            startIcon={<OpenInNewSharpIcon />}>
            Launch
          </Button>
        )}

      </Box>
      <Typography variant='body2'>Since: {format(new Date(product.subscription_details.since), "MM-dd-yyyy")}</Typography>
      <Typography variant='body2'>Licenses: {product.subscription_details.num_licenses}</Typography>
    </CardContent>
    <CardActions>
      <Button size='small' color='primary'>
        Manage Subscription
      </Button>
    </CardActions>
  </Card>
);

export default ActiveProductCard;
