import React, { useEffect, useState } from 'react';
import { Box, Switch, FormControlLabel, TextField, Typography, FormGroup, Divider } from '@mui/material';

const Enhancements = ({ connection, availableEnhancements, user }) => {
    const [readOnly, setReadOnly] = useState(user.role == 'altvia_user');

    useEffect(() => {
        if (user.role == 'altvia_user') { return; }

        const connectionCreating = connection.status == 'creating';
        setReadOnly(connectionCreating);
    }, [connection])

    const [enhancements, setEnhancements] = useState({
        extraction: false,
        summarization: false,
        relate: false,
        // New state properties for relate options
        accounts: { enabled: false, tables: '' },
        contacts: { enabled: false, tables: '' },
        entities: { enabled: false, tables: '' },
    });

    const handleToggle = (event) => {
        setEnhancements({ ...enhancements, [event.target.name]: event.target.checked });
    };

    // Handler for toggling relate options and updating table names
    const handleRelateChange = (name, value, isTable = false) => {
        setEnhancements(prevState => ({
            ...prevState,
            [name]: isTable ? { ...prevState[name], tables: value } : { ...prevState[name], enabled: value }
        }));
    };

    return (
        <Box>
            {/* <Typography variant="h6">Configure Enhancements</Typography> */}
            <FormGroup>
                <Typography variant='h6'>Extraction Enhancement Settings</Typography>
                {availableEnhancements.extraction && (
                    <FormControlLabel
                        control={<Switch disabled={readOnly} checked={enhancements.extraction} onChange={handleToggle} name="extraction" />}
                        label="Extraction"
                    />
                )}
                {enhancements.extraction && (
                    <Box sx={{ mt: 2 }}>
                        {/* I am not sure there are any... maybe options */}
                    </Box>
                )}
                <Divider sx={{ mt: 3, mb: 3 }}></Divider>
                <Typography variant='h6'>Summarization Enhancement Settings</Typography>
                {availableEnhancements.summarization && (
                    <FormControlLabel
                        control={<Switch disabled={readOnly} checked={enhancements.summarization} onChange={handleToggle} name="summarization" />}
                        label="Summarization"
                    />
                )}
                {enhancements.summarization && (
                    <Box sx={{ mt: 2 }}>
                        <TextField sx={{ mb: 2 }}
                            disabled={readOnly}
                            fullWidth
                            label="Source Column"
                            variant="outlined"
                            value={""}
                            onChange={() => { }}
                            size='small'
                        />

                        <TextField sx={{ mb: 2 }}
                            disabled={readOnly}
                            fullWidth
                            label="Destination Column"
                            variant="outlined"
                            value={""}
                            onChange={() => { }}
                            size='small'
                        />
                        <TextField
                            disabled={readOnly}
                            fullWidth
                            label="Prompt"
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={""}
                            onChange={() => { }}
                            multiline
                            rows={4}
                            size="small"
                            required
                        />

                    </Box>
                )}
                <Divider sx={{ mt: 3, mb: 3 }}></Divider>
                <Typography variant='h6'>Relation Enhancement Settings</Typography>
                {availableEnhancements.relate && (
                    <FormControlLabel
                        control={<Switch disabled={readOnly} checked={enhancements.relate} onChange={handleToggle} name="relate" />}
                        label="Relate"
                    />
                )}
                {enhancements.relate && (
                    <>
                        {/* New switches for Accounts/Companies, Contacts, and Entities */}
                        <FormControlLabel
                            control={<Switch disabled={readOnly} checked={enhancements.accounts.enabled} onChange={(e) => handleRelateChange('accounts', e.target.checked)} name="accounts" />}
                            label="Accounts/Companies"
                        />
                        {enhancements.accounts.enabled && (
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                label="Tables to search for Accounts/Companies"
                                variant="outlined"
                                value={enhancements.accounts.tables}
                                onChange={(e) => handleRelateChange('accounts', e.target.value, true)}
                                sx={{ mb: 2 }}
                            />
                        )}

                        <FormControlLabel
                            control={<Switch disabled={readOnly} checked={enhancements.contacts.enabled} onChange={(e) => handleRelateChange('contacts', e.target.checked)} name="contacts" />}
                            label="Contacts"
                        />
                        {enhancements.contacts.enabled && (
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                label="Tables to search for Contacts"
                                variant="outlined"
                                value={enhancements.contacts.tables}
                                onChange={(e) => handleRelateChange('contacts', e.target.value, true)}
                                sx={{ mb: 2 }}
                            />
                        )}

                        <FormControlLabel
                            control={<Switch disabled={readOnly} checked={enhancements.entities.enabled} onChange={(e) => handleRelateChange('entities', e.target.checked)} name="entities" />}
                            label="Entities"
                        />
                        {enhancements.entities.enabled && (
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                label="Tables to search for Entities"
                                variant="outlined"
                                value={enhancements.entities.tables}
                                onChange={(e) => handleRelateChange('entities', e.target.value, true)}
                                sx={{ mb: 2 }}
                            />
                        )}
                    </>
                )}
            </FormGroup>
        </Box>
    );
};

export default Enhancements;
