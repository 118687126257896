import { useState } from 'react';
import {
  Paper,
  Box,
  ButtonGroup,
  Button,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const ROICalculator = () => {
  const [selectedProfile, setSelectedProfile] = useState('emerging');

  const firmProfiles = {
    emerging: {
      name: 'Emerging Fund',
      details: {
        aum: '$250M',
        deals: '5-10/year',
        teamSize: '10-15 people',
        lpCount: '30-50 LPs'
      },
      moduleUsage: {
        fundraising: {
          users: 4,
          timeSavings: {
            lpReporting: 240,           // 20 hours/month
            documentManagement: 180,     // 15 hours/month
            emailCampaigns: 120,        // 10 hours/month
            subscriptionProcessing: 60   // 5 hours/month
          }
        },
        dealTeam: {
          users: 6,
          timeSavings: {
            dealFlow: 360,              // 30 hours/month
            dueDiligence: 240,          // 20 hours/month
            reporting: 120,             // 10 hours/month
            dataRoom: 60                // 5 hours/month
          }
        }
      },
      setup: {
        tier: 'Team',
        implementation: 1,    // weeks
        training: 1          // weeks
      }
    },
    growth: {
      name: 'Growth Fund',
      details: {
        aum: '$1B',
        deals: '15-20/year',
        teamSize: '30-40 people',
        lpCount: '100-150 LPs'
      },
      moduleUsage: {
        fundraising: {
          users: 8,
          timeSavings: {
            lpReporting: 480,           // 40 hours/month
            documentManagement: 360,     // 30 hours/month
            emailCampaigns: 240,        // 20 hours/month
            subscriptionProcessing: 120  // 10 hours/month
          }
        },
        dealTeam: {
          users: 15,
          timeSavings: {
            dealFlow: 600,              // 50 hours/month
            dueDiligence: 480,          // 40 hours/month
            reporting: 240,             // 20 hours/month
            dataRoom: 120               // 10 hours/month
          }
        },
        irOps: {
          users: 6,
          timeSavings: {
            capitalCalls: 240,          // 20 hours/month
            distributions: 180,          // 15 hours/month
            reporting: 300,             // 25 hours/month
            compliance: 180             // 15 hours/month
          }
        }
      },
      setup: {
        tier: 'Business',
        implementation: 6,   // weeks
        training: 2          // weeks
      }
    },
    established: {
      name: 'Established Fund',
      details: {
        aum: '$5B+',
        deals: '30+/year',
        teamSize: '75+ people',
        lpCount: '300+ LPs'
      },
      moduleUsage: {
        fundraising: {
          users: 15,
          timeSavings: {
            lpReporting: 720,           // 60 hours/month
            documentManagement: 480,     // 40 hours/month
            emailCampaigns: 360,        // 30 hours/month
            subscriptionProcessing: 240  // 20 hours/month
          }
        },
        dealTeam: {
          users: 30,
          timeSavings: {
            dealFlow: 960,              // 80 hours/month
            dueDiligence: 720,          // 60 hours/month
            reporting: 480,             // 40 hours/month
            dataRoom: 240               // 20 hours/month
          }
        },
        irOps: {
          users: 12,
          timeSavings: {
            capitalCalls: 360,          // 30 hours/month
            distributions: 300,          // 25 hours/month
            reporting: 480,             // 40 hours/month
            compliance: 360             // 30 hours/month
          }
        },
        portfolioManagement: {
          users: 18,
          timeSavings: {
            monitoring: 600,            // 50 hours/month
            valuations: 480,            // 40 hours/month
            reporting: 360,             // 30 hours/month
            analytics: 360              // 30 hours/month
          }
        }
      },
      setup: {
        tier: 'Enterprise',
        implementation: 12,   // weeks
        training: 2          // weeks
      }
    }
  };

  const calculateROI = (profile) => {
    const hourlyRate = 75;
    let totalAnnualTimeSavings = 0;
    let totalUsers = 0;
    let moduleCount = 0;

    // Calculate total time savings and users
    Object.values(profile.moduleUsage).forEach(module => {
      moduleCount++;
      totalUsers += module.users;
      Object.values(module.timeSavings).forEach(hours => {
        totalAnnualTimeSavings += hours;
      });
    });

    // Get base pricing
    const basePricing = {
      Team: 15000,
      Business: 40000,
      Enterprise: 70000
    };

    // Get module pricing
    const modulePricing = {
      fundraising: { Team: 950, Business: 1125, Enterprise: 1350 },
      dealTeam: { Team: 1000, Business: 1500, Enterprise: 2200 },
      irOps: { Team: 1000, Business: 1500, Enterprise: 1875 },
      portfolioManagement: { Team: 1750, Business: 2100, Enterprise: 2450 }
    };

    // Calculate volume discount
    const getDiscount = (count) => {
      switch(count) {
        case 2: return 0.85;
        case 3: return 0.75;
        case 4: return 0.65;
        default: return 1;
      }
    };
    
    // Calculate annual costs
    const platformFee = basePricing[profile.setup.tier];
    let licenseFees = 0;
    Object.entries(profile.moduleUsage).forEach(([moduleName, usage]) => {
      licenseFees += usage.users * modulePricing[moduleName][profile.setup.tier];
    });
    licenseFees *= getDiscount(moduleCount);

    const implementationCost = 20000; // Fixed implementation cost
    const trainingCost = 10000;       // Fixed training cost

    // Calculate benefits
    const annualTimeSavingsValue = totalAnnualTimeSavings * hourlyRate;
    
    // Calculate 3-year projection
    return [
      {
        year: 'Year 1',
        costs: platformFee + licenseFees + implementationCost + trainingCost,
        benefits: annualTimeSavingsValue * 0.7, // 70% realization in first year
        users: totalUsers,
        hoursSaved: totalAnnualTimeSavings * 0.7
      },
      {
        year: 'Year 2',
        costs: platformFee + licenseFees,
        benefits: annualTimeSavingsValue * 0.9, // 90% realization in second year
        users: totalUsers,
        hoursSaved: totalAnnualTimeSavings * 0.9
      },
      {
        year: 'Year 3',
        costs: platformFee + licenseFees,
        benefits: annualTimeSavingsValue * 1.1, // 110% realization in third year (additional efficiencies)
        users: totalUsers,
        hoursSaved: totalAnnualTimeSavings * 1.1
      }
    ];
  };

  const profile = firmProfiles[selectedProfile];
  const roiData = calculateROI(profile);

  return (
    <Box sx={{ width: '100%' }}>
      {/* Profile Selection */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
        <ButtonGroup variant="contained">
          {Object.keys(firmProfiles).map((profileId) => (
            <Button
              key={profileId}
              onClick={() => setSelectedProfile(profileId)}
              variant={selectedProfile === profileId ? 'contained' : 'outlined'}
              sx={{ textTransform: 'capitalize' }}
            >
              {firmProfiles[profileId].name}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      {/* Firm Profile */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Firm Profile</Typography>
            <Stack spacing={2}>
              {Object.entries(profile.details).map(([key, value]) => (
                <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography color="text.secondary" sx={{ textTransform: 'capitalize' }}>
                    {key.replace(/([A-Z])/g, ' $1').trim()}:
                  </Typography>
                  <Typography fontWeight="medium">{value}</Typography>
                </Box>
              ))}
            </Stack>
          </Paper>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Platform Setup</Typography>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography color="text.secondary">Tier:</Typography>
                <Typography fontWeight="medium">{profile.setup.tier}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography color="text.secondary">Implementation:</Typography>
                <Typography fontWeight="medium">{profile.setup.implementation} weeks</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography color="text.secondary">Training:</Typography>
                <Typography fontWeight="medium">{profile.setup.training} weeks</Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      {/* Time Savings Breakdown */}
      <Paper sx={{ mb: 4, p: 3 }}>
        <Typography variant="h6" gutterBottom>Time Savings Breakdown</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Module</TableCell>
                <TableCell>Activity</TableCell>
                <TableCell align="right">Users</TableCell>
                <TableCell align="right">Hours/Month</TableCell>
                <TableCell align="right">Annual Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(profile.moduleUsage).map(([moduleName, usage]) => (
                Object.entries(usage.timeSavings).map(([activity, hours]) => (
                  <TableRow key={`${moduleName}-${activity}`}>
                    <TableCell>{moduleName}</TableCell>
                    <TableCell>{activity.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                    <TableCell align="right">{usage.users}</TableCell>
                    <TableCell align="right">{hours/12}</TableCell>
                    <TableCell align="right">${(hours * 75).toLocaleString()}</TableCell>
                  </TableRow>
                ))
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* ROI Chart */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>3-Year ROI Projection</Typography>
        <Box sx={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={roiData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="costs"
                name="Annual Costs"
                stroke="#ff4444"
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="benefits"
                name="Annual Benefits"
                stroke="#4caf50"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="hoursSaved"
                name="Hours Saved"
                stroke="#2196f3"
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        {/* Summary Stats */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {roiData.map((year) => (
            <Grid size={{xs: 12, md: 4}} key={year.year}>
              <Paper sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="h6" gutterBottom>{year.year}</Typography>
                <Stack spacing={1}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Costs:</Typography>
                    <Typography>${year.costs.toLocaleString()}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Benefits:</Typography>
                    <Typography>${year.benefits.toLocaleString()}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>Hours Saved:</Typography>
                    <Typography>{Math.round(year.hoursSaved).toLocaleString()}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>ROI:</Typography>
                    <Typography>
                      {Math.round((year.benefits - year.costs) / year.costs * 100)}%
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ROICalculator;