import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CardActions
} from "@mui/material";

const AvailableProductCard = ({ product, handleOpenDrawer }) => (
  <Card
    sx={{
      height: "100%",
      cursor: "pointer",
      border: "1px solid #d5e6ee",
      "&:hover": { backgroundColor: "#e0f7fa" },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}
    onClick={() => handleOpenDrawer(product)}
  >
    <CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h5">{product.name}</Typography>
        {product.logo_url && (
          <img
            src={product.logo_url}
            alt={`${product.name} logo`}
            style={{ width: "40px", height: "40px" }}
          />
        )}
      </Box>
      {(product.short_description || product.description) && (
        <Typography
          // truncate after 3 lines. See: https://stackoverflow.com/a/71324014
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden'
          }}
        >
          {product.short_description || product.description}
        </Typography>
      )}
    </CardContent>
    <CardActions>
      <Button size="small" color="primary">
        Activate
      </Button>
    </CardActions>
  </Card>
);

export default AvailableProductCard;
