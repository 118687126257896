import React from 'react';
import SubSidebar from '../../components/SubSidebar';
import { Link, Outlet, useLoaderData, useOutletContext, useParams } from 'react-router-dom';
import { Box, Button, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { authedApiFetch } from '../../utils/Api';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export async function loader({ params }) {
  const tables = await authedApiFetch({
    endpoint: `/tenants/${params.tenantId}/data_tables`,
    method: 'GET'
  })

  return { tables };
}

const DataTables = () => {
  const { tenant, user } = useOutletContext();

  let { tables } = useLoaderData();
  tables = tables.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  
    // names must be equal
    return 0;
  })

  const { dataTableId } = useParams(); // get ID if in route

  return (
    <div id='thisone' style={{height: 'calc(100vh - 90px)', boxSizing: 'border-box', display: 'flex' }}>
      <SubSidebar
        tenant={tenant}
        activeTab={'Data Tables'}
        user={user}
      >
        <Typography variant="h4">Data Tables</Typography>
        {(user.role == 'altvia_admin' || user.role == 'altvia_power_user') &&
          <Button
          sx={{ mt: 2 }}
          startIcon={<AddCircleOutlineIcon />}
          component={Link}
          to="new"
          variant="outlined"
          fullWidth
          >
            Create Table Access
          </Button>
        }
        <List>
          {tables.map((table) => (
            <ListItemButton
              selected={table.id == dataTableId}
              key={table.name}
              component={Link}
              to={`/tenants/${tenant.id}/data-tables/${table.id}/${user.tenant_id ? 'records' : 'settings'}`}
            >
              <ListItemText
                primary={table.alias || table.name}
              />
            </ListItemButton>
            )
          )}
        </List>
      </SubSidebar>
      <Box
        component="main"
        boxSizing='border-box'
        height="100%"
        width="calc(100vw - 300px)"
        sx={{ p: 3, overflowY: 'auto' }}
        display='flex'
        flexDirection='column'
        rowGap="16px"
      >
        <Outlet context={{ tables, user }} />
      </Box>
    </div>
  )
}

export default DataTables;