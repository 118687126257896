import React from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import UserMenu from "./UserMenu";
import TenantSwitcher from "./TenantSwitcher";
import logo from "../assets/images/altvia-logo-light.svg";

const BottomMenu = ({ user, tenants }) => {
  return (
    <AppBar position='fixed' sx={{ top: "auto", height: "64px", bottom: 0, zIndex: 1300, backgroundColor: "rgb(55, 71, 79)" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant='h6' sx={{ flexGrow: 1, color: "#fff" }}>
            <img src={logo} alt='Altvia Logo' style={{ height: "1.5rem", marginRight: "0.5rem" }} />
            ONE
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!user.tenant_id && <TenantSwitcher tenants={tenants} />}
          <UserMenu user={user} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BottomMenu;
