import {
  Paper,
  Typography,
  Stack,
  Box,
  Divider,
  ButtonGroup,
  Button
} from '@mui/material';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const PricingSummary = ({
  selectedTier,
  selectedModules,
  selectedAddOns,
  moduleCosts,
  addOnCosts,
  volumeDiscount,
  yearTerm,
  onYearTermChange,
  totalCost,
  yearDiscount,
  enterpriseSeats,
  perSeatPrice
}) => {
  const annualCost = moduleCosts + addOnCosts;
  const discountedAnnualCost = annualCost * (yearTerm > 1 ? (1 - yearDiscount/100) : 1);

  return (
    <Paper 
      sx={{ 
        p: 3, 
        bgcolor: 'primary.light',
        position: 'sticky',
        top: 24
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ textTransform: 'capitalize' }}>
        {selectedTier} Tier Summary
      </Typography>

      <Stack spacing={2}>
        <ButtonGroup fullWidth size="small" sx={{ mb: 2 }}>
          {[1, 2, 3].map((years) => (
            <Button
              key={years}
              variant={yearTerm === years ? 'contained' : 'outlined'}
              onClick={() => onYearTermChange(years)}
            >
              {years} {years === 1 ? 'Year' : 'Years'}
            </Button>
          ))}
        </ButtonGroup>

        {selectedTier === 'enterprise' ? (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Enterprise Seats:</Typography>
              <Typography fontWeight="medium">{enterpriseSeats}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Price Per Seat:</Typography>
              <Typography fontWeight="medium">{formatCurrency(perSeatPrice)}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Platform + Modules:</Typography>
              <Typography fontWeight="medium">
                {formatCurrency(moduleCosts)}
              </Typography>
            </Box>
          </>
        ) : (
          selectedModules.length > 0 && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Core Modules:</Typography>
                <Typography fontWeight="medium">{selectedModules.length}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Volume Discount:</Typography>
                <Typography fontWeight="medium">
                  {Math.round(volumeDiscount)}%
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Module Subtotal:</Typography>
                <Typography fontWeight="medium">
                  {formatCurrency(moduleCosts)}
                </Typography>
              </Box>
            </>
          )
        )}

        {selectedAddOns.length > 0 && (
          <>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Add-ons:</Typography>
              <Typography fontWeight="medium">{selectedAddOns.length}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Add-ons Subtotal:</Typography>
              <Typography fontWeight="medium">
                {formatCurrency(addOnCosts)}
              </Typography>
            </Box>
          </>
        )}

        {(selectedModules.length > 0 || selectedTier === 'enterprise') && (
          <>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">Annual Total:</Typography>
              <Typography variant="subtitle1" fontWeight="medium">
                {formatCurrency(annualCost)}
              </Typography>
            </Box>

            {yearTerm > 1 && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Term Length:</Typography>
                  <Typography fontWeight="medium">{yearTerm} Years</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Term Discount:</Typography>
                  <Typography fontWeight="medium">{Math.round(yearDiscount)}%</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography>Annual Price with Discount:</Typography>
                  <Typography fontWeight="medium">
                    {formatCurrency(discountedAnnualCost)}
                  </Typography>
                </Box>
              </>
            )}

            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">
                {yearTerm > 1 ? `${yearTerm}-Year Total:` : 'Total:'}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                {formatCurrency(totalCost)}
              </Typography>
            </Box>
            {yearTerm > 1 && (
              <Typography variant="body2" color="text.secondary" align="right">
                ({formatCurrency(discountedAnnualCost)} per year)
              </Typography>
            )}
          </>
        )}

        <Typography variant="caption" align="center" color="text.secondary">
          *Includes platform fee, {selectedTier === 'enterprise' ? 'all modules' : 'selected modules'}, and add-ons
        </Typography>
      </Stack>
    </Paper>
  );
};

export default PricingSummary;